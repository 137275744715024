import { getAccountData } from "./getAccountData";

export default function getTinymceAPIKey() {
  let agencyId = Number(getAccountData("agencyId"));

  if (agencyId === 1 && process.env.REACT_APP_PRODUCTION_MODE && process.env.REACT_APP_PRODUCTION_MODE !== "true") {
    return "ok2pl6dwqcu3dz9bbq14a5nj2wl3eskhae5npcza4vp7yy19"; //shahin@orangetoolz.com
  } else if (agencyId === 1 && getAccountData("userId") < 10000) {
    return "qi8op9298ay3enamb02a5l3547fw5v0ta0xcafoj3tfi8smg"; //info@quickconnect
  } else if (agencyId === 1) {
    return "2rxgmv1zyxbpvgzr5n6m9cixelzls9wdckt1d3etfub3xmjm"; //mehedi@orangetoolz
  } else if (agencyId === 53) {
    return "vg1npymq1chv67z1a10ove031pomuia8dis2xd016c4au9gc"; //dev.salespype
  } else if (agencyId === 125) {
    return "bl5f40flknaw1bqbtz37clw421lj6oza0aa330fmzko60avh"; //salespype.gdev
  } else if (agencyId > 50 && agencyId < 200) {
    return "mkobqgy2lg21yecbury3yaftxadek52xavr8k0frcr7j17pi"; //atish@orangetoolz.com
  }
  return "ok2pl6dwqcu3dz9bbq14a5nj2wl3eskhae5npcza4vp7yy19";
}
